import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import parse, { domToReact } from 'html-react-parser';
import { IPInfoContext } from 'ip-info-react';
import _ from 'lodash';

// Import middlewares
import api from './../../other/middlewares/api';

// Import actions
import { setCurrentUser } from './../../other/actions/auth';

// Import helpers
import { validateFields } from './../../other/helpers/validate-fields';
import { slugByPageId } from './../../other/helpers/route-finder';

// Import configs
import keys from './../../other/configs/keys';

// Import components
import Input from './../input/input';
import Select from './../select/select';

interface props {
	'pageId': string,
	'notifications': any,
	'isLoading': any
};

declare const window: any;

const SignUp = (props: props) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const userInfo = useContext(IPInfoContext);

	const auth = useSelector((state: {[key: string]: any}) => state.auth);
	const lang = useSelector((state: {[key: string]: any}) => state.lang);
	const routes = useSelector((state: {[key: string]: any}) => state.routes);
	const generalText = useSelector((state: {[key: string]: any}) => state.generalText);

	const initUser: {[key: string]: any} = {
		'firstName': '',
		'lastName': '',
		'country': '',
		'category': '',
		'email': '',
		'password': '',
		'invitationCodeUsed': '',
	}

	const [showInvitationInput, setShowInvitationInput] = useState<boolean>(false);
	const [isEmailFocused, setIsEmailFocused] = useState<boolean>(false);
	const [user, setUser] = useState<{[key: string]: any}>(initUser);
	const [errors, setErrors] = useState<{[key: string]: any}>({});	

	useEffect(() => {
		if (userInfo.ip === undefined) { return; }

		setUser(prevState => ({...prevState, 'country': userInfo.country_name}));
	}, [userInfo]);

	const onSubmitForm = async (e: any) => {
		e.preventDefault();

		// If user is already signed in
		if (auth.isAuthenticated) {
			navigate(slugByPageId('main', lang, routes));
			return;
		}

		props.isLoading(true);

		// Validate fields
		let stateClone: any = _.cloneDeep(user);
		
		if (stateClone.invitationCodeUsed === '') { delete stateClone.invitationCodeUsed; }
		if (stateClone.country === '') { delete stateClone.country; }
		delete stateClone.category;

		// Validation
		let fieldErrors = validateFields(stateClone, ['user', 'filter']);

		if (!_.isEmpty(fieldErrors)) {
			props.isLoading(false);
			setErrors(fieldErrors);

			return;
		}

		if (userInfo.ip !== undefined) {
			stateClone['networkDetails'] = userInfo;
		}

		api.post('/sign-up', stateClone).then((res) => {
			props.isLoading(false);

			// If user registered
			if (res.status === 200) {
				setUser(initUser);

				// Facebook event CompleteRegistration
				if (window.fbq) {
					window.fbq('track', 'CompleteRegistration', {
						'event_time': Math.floor(Date.now() / 1000),
						'event_name': 'CompleteRegistration',
						'event_source_url': window.location.href,
						'action_source': 'website'
					});
				}

				// window.ttq?.track('CompleteRegistration', {
				// 	'contents': [{
				// 		'content_name': 'Sign-up', // string. The name of the page or product. Example: "shirt".
				// 	}],
				// });

				dispatch(setCurrentUser({...auth, 'toggleUpdate': !auth.toggleUpdate}));

				setTimeout(() => {
					navigate(slugByPageId('user-profiles', lang, routes));
				}, 1000);
			}

		}).catch((err) => {
			props.isLoading(false);
			setUser(prevState => ({...prevState, 'password': initUser.password}));

			props.notifications(err.response?.data?.messages);
		});
	}

	return (
		<form onSubmit={(e) => onSubmitForm(e)}>
			<div className="AUTH no-padding">
				<div className="grid">
					<div className="row">
						<div className="col-12">
							<div className="SECTION__title">{ generalText.signUp?.title }</div>
						</div>

						<div className="col-12 col-sm-6">
							<div className="FIELD">
								<Input
									value={user.firstName}
									label={generalText.signUp?.input1?.label}
									placeholder={generalText.signUp?.input1?.placeholder}
									type="text"
									errors={errors.firstName}
									onChange={(val: string) => setUser(prevState => ({...prevState, 'firstName': val}))}
								/>
							</div>
						</div>

						<div className="col-12 col-sm-6">
							<div className="FIELD">
								<Input
									value={user.lastName}
									label={generalText.signUp?.input2?.label}
									placeholder={generalText.signUp?.input2?.placeholder}
									type="text"
									errors={errors.lastName}
									onChange={(val: string) => setUser(prevState => ({...prevState, 'lastName': val}))}
								/>
							</div>
						</div>

						<div className="col-12">
							<div className="FIELD">
								<Select
									value={user.category}
									label={generalText.signUp?.select1?.label}
									placeholder={generalText.signUp?.select1?.placeholder}
									icon={generalText.signUp?.select1?.icon}
									nullable={true}
									strict={true}
									options={[]}
									dbOptions={'categories'}
									errors={errors.category}
									onChange={(val: string) => setUser(prevState => ({...prevState, 'category': val}))}
									setNotifications={(val: any) => props.notifications(val)}
								/>
							</div>
						</div>

						<div className="col-12">
							<div className="FIELD">
								<Input
									value={user.email}
									label={generalText.signUp?.input3?.label}
									placeholder={generalText.signUp?.input3?.placeholder}
									icon={generalText.signUp?.input3?.icon}
									type="text"
									errors={errors.email}
									onChange={(val: string) => setUser(prevState => ({...prevState, 'email': val.replace(/ /g,'')}))}
									onFocus={(val: any) => (val.type === 'focus' && setIsEmailFocused(true))}
									onBlur={(val: any) => (val.type === 'blur' && setIsEmailFocused(false))}
								/>

								{
									isEmailFocused &&
									<div className="FIELD__note" dangerouslySetInnerHTML={{ __html: generalText.signUp?.input3?.text1 }}></div>
								}
							</div>
						</div>

						<div className="col-12">
							<div className="FIELD">
								<Input
									value={user.password}
									label={generalText.signUp?.input4?.label}
									placeholder={generalText.signUp?.input4?.placeholder}
									icon={generalText.signUp?.input4?.icon}
									type="password"
									errors={errors.password}
									onChange={(val: string) => setUser(prevState => ({...prevState, 'password': val}))}
								/>
							</div>
						</div>

						<div className="col-12">
							{
								!showInvitationInput && generalText.signUp?.input5?.text1 &&
								<p><span className="LINK" onClick={() => setShowInvitationInput(!showInvitationInput)}>{ generalText.signUp?.input5?.text1 }</span></p>
							}

							{
								showInvitationInput &&
								<div className="FIELD">
									<Input
										value={user.invitationCodeUsed}
										label={generalText.signUp?.input5?.label}
										placeholder={generalText.signUp?.input5?.placeholder}
										type="text"
										errors={errors.invitationCodeUsed}
										onChange={(val: string) => setUser(prevState => ({...prevState, 'invitationCodeUsed': val}))}
									/>
								</div>
							}
						</div>

						{
							generalText.signUp?.button1?.text &&
							<div className="col-12 col-sm-8 push-sm-2">
								<button type="submit" className="BUTTON">{ generalText.signUp?.button1?.text }</button>
							</div>
						}

						{
							generalText.signUp?.text1 &&
							<div className="col-12 text-center">
								{
									parse(generalText.signUp?.text1, {
										replace: (domNode: any) => {
											if (domNode.attribs?.class === '%repStr1%') {
												return <Link to={slugByPageId('sign-in', lang, routes)} className="LINK">{ domToReact(domNode.children) }</Link>;
											}
										}
									})
								}
							</div>
						}

						{
							generalText.signUp?.text2 &&
							<div className="col-12">
								<div className="AUTH__consent text-center">
									{
										parse(generalText.signUp?.text2, {
											replace: (domNode: any) => {
												if (domNode.attribs?.class === '%repStr1%') {
													return <Link to={slugByPageId('terms', lang, routes)} className="LINK">{ domToReact(domNode.children) }</Link>;
												}

												if (domNode.attribs?.class === '%repStr2%') {
													return <Link to={slugByPageId('privacy', lang, routes)} className="LINK">{ domToReact(domNode.children) }</Link>;
												}

												if (domNode.attribs?.class === '%repStr3%') {
													return <Link to={slugByPageId('cookies', lang, routes)} className="LINK">{ domToReact(domNode.children) }</Link>;
												}

												if (domNode.attribs?.class === '%repStr4%') {
													return <span onClick={() => {window.open('mailto:' + keys.contactEmail)}} className="LINK">{ domToReact(domNode.children) }</span>;
												}
											}
										})
									}
								</div>
							</div>
						}
					</div>
				</div>
			</div>
		</form>
	);
}

export default SignUp;