let keysByName: any = {};

if (process.env?.REACT_APP_NAME === 'FindUpp') {
	keysByName['apiPort'] = 8888;
	keysByName['apiBasePort'] = 8888;
}

if (process.env?.REACT_APP_NAME === 'TekBruv') {
	keysByName['apiPort'] = 8889;
	keysByName['apiBasePort'] = 8889;
}

const keys = {
	'localHostURL': '192.168.1.85',
	'contactEmail': 'hello@' + process.env?.REACT_APP_NAME?.toLowerCase() + '.com',
	...keysByName,

	// 'maxUploadFileSize': 1024 * 1024 * 64,
	
	// // Credits
	// 'creditsPriceMultiplier': 0.1,
	// 'dailyCreditsAmount': 1,
	// 'hoursToUpdateCredits': 12,
	// 'hoursToAllowProfileRaise': 6,
	// 'referralEarning': 50,

	// // Profile
	// 'hoursToCountAsActiveProfile': 168, // 1 week
	// 'minutesProfileIsActive': 15,
	// 'daysProfileInactive': 365,

	// // Function prices
	// 'newListingPostPrice': 10,
	// 'newFeedbackPostPrice': 10,
	// 'newQuestionPostPrice': 10,
	// 'newOpportunityPostEarning': 5,
	// 'newEducationalPostEarning': 5,
	// 'newPromotionalPostPrice': 10,
	// 'newCommentEarning': 1,
	// 'viewOfferPrice': 0,
	// 'newProfilePrice': 20,
	// 'raiseProfilePrice': 10,
	// 'unlockPostsPrice': 50,
	// 'sendMessagePrice': 0,

	// // Page limits
	// 'filteredProfilesLimit': 12,
};

export default keys;
